import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { Helmet } from 'react-helmet';
import './index.css';
import App from './landing_page/App';
import PaymentPage from './payment_page/PaymentPage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import JsonlCreatorApp from './openai_product/jsonl_creator';
import ChatCompletionApp from './openai_product/chatcompletion';
import FineTuningApp from './openai_product/finetuning';
import HeaderDashboard from './openai_product/header_dashboard';
import Home from './openai_product/home';
import Account from './account/account';
import ContactUs from './contact/ContactUs';
import Footer from './Footer';
import ConditionSercice from './legal/conditionService';
import PolitiqueConfidentialite from './legal/politiqueConfidentialite';
import './i18n'; // Importer la configuration i18n
import LanguageSwitcher from './LanguageSwitcher'; // Importer le composant LanguageSwitcher
import HelmetPageInfo from './pageInfo'; // Importer le composant PageInfo
import HeaderDashboard2 from './openai_product/header_dashboard2';
import BackendStatusChecker from './backend-check/BackendStatusChecker';
import { addGoogleTag } from './googleTag'; // Import the file googleTag.js
import { useTranslation } from 'react-i18next';
import NotificationPopup from './components/NotificationPopup';

const originalFetch = window.fetch;
window.fetch = function (url, options = {}) {
  options.headers = {
    ...options.headers,
  };
  options.credentials = 'include'; // Inclure les credentials
  return originalFetch(url, options);
};

const nonce = '5a889457bc8de445b9e49d3ec943e426'; // Utiliser le nonce fixe
addGoogleTag(nonce);

const cache = createCache({
  key: 'css',
  nonce: nonce,
});

const AppWrapper = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('landing.title');
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = t('landing.seo_description');
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = t('landing.seo_keywords');
    document.head.appendChild(metaKeywords);

    const metaOgTitle = document.createElement('meta');
    metaOgTitle.property = 'og:title';
    metaOgTitle.content = t('landing.title');
    document.head.appendChild(metaOgTitle);

    const metaOgDescription = document.createElement('meta');
    metaOgDescription.property = 'og:description';
    metaOgDescription.content = t('landing.seo_description');
    document.head.appendChild(metaOgDescription);

    const metaOgImage = document.createElement('meta');
    metaOgImage.property = 'og:image';
    metaOgImage.content = '/logo.png';
    document.head.appendChild(metaOgImage);

    const metaOgUrl = document.createElement('meta');
    metaOgUrl.property = 'og:url';
    metaOgUrl.content = window.location.href;
    document.head.appendChild(metaOgUrl);

    const metaOgType = document.createElement('meta');
    metaOgType.property = 'og:type';
    metaOgType.content = 'website';
    document.head.appendChild(metaOgType);

    const metaTwitterCard = document.createElement('meta');
    metaTwitterCard.name = 'twitter:card';
    metaTwitterCard.content = 'summary_large_image';
    document.head.appendChild(metaTwitterCard);

    const metaTwitterTitle = document.createElement('meta');
    metaTwitterTitle.name = 'twitter:title';
    metaTwitterTitle.content = t('landing.title');
    document.head.appendChild(metaTwitterTitle);

    const metaTwitterDescription = document.createElement('meta');
    metaTwitterDescription.name = 'twitter:description';
    metaTwitterDescription.content = t('landing.seo_description');
    document.head.appendChild(metaTwitterDescription);

    const metaTwitterImage = document.createElement('meta');
    metaTwitterImage.name = 'twitter:image';
    metaTwitterImage.content = '/logo.png';
    document.head.appendChild(metaTwitterImage);
  }, [t]);

  return (
    <CacheProvider value={cache}>
      <React.StrictMode>
        <Router>
          <HelmetPageInfo />
          <BackendStatusChecker>
            <LanguageSwitcher />
            <NotificationPopup /> {/* Déplacez NotificationPopup ici */}
            <Routes>
              <Route path="/" element={<>
                <HelmetPageInfo />
                <App />
                <Footer />
              </>} />
              <Route path="/payment" element={<>
                <HelmetPageInfo />
                <HeaderDashboard />
                <PaymentPage />
              </>} />
              <Route path="/jsonl-creator" element={<>
                <HelmetPageInfo />
                <HeaderDashboard />
                <JsonlCreatorApp />
                <ChatCompletionApp />
              </>} />
              <Route path="/chat-completion" element={<>
                <HelmetPageInfo />
                <HeaderDashboard />
                <ChatCompletionApp />
                <Footer />
              </>} />
              <Route path="/fine-tuning" element={<>
                <HelmetPageInfo />
                <HeaderDashboard />
                <FineTuningApp />
                <ChatCompletionApp />
                <Footer />
              </>} />
              <Route path="/home" element={<>
                <HelmetPageInfo />
                <HeaderDashboard />
                <Home />
              </>} />
              <Route path="/account" element={<>
                <HelmetPageInfo />
                <HeaderDashboard />
                <Account />
                <Footer />
              </>} />
              <Route path="/contact-us" element={<>
                <HelmetPageInfo />
                <HeaderDashboard />
                <ContactUs />
                <Footer />
              </>} />
              <Route path="/terms-of-service" element={<>
                <HelmetPageInfo />
                <HeaderDashboard2 />
                <ConditionSercice />
                <Footer />
              </>} />
              <Route path="/privacy-policy" element={<>
                <HelmetPageInfo />
                <HeaderDashboard2 />
                <PolitiqueConfidentialite />
                <Footer />
              </>} />
            </Routes>
          </BackendStatusChecker>
        </Router>
      </React.StrictMode>
    </CacheProvider>
  );
  };
  
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<AppWrapper />);
  
  reportWebVitals();