import React, { useState, useEffect, useCallback } from 'react';
import './ModelSelectionPopup.css';
import img1 from '../images/design-imag2.svg';

function ModelSelectionPopup({ onSelectModel }) {
    const [activeTab, setActiveTab] = useState('fineurai-models');
    const [publicModels, setPublicModels] = useState([]);
    const [userModels, setUserModels] = useState([]);
    const [message, setMessage] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [userPage, setUserPage] = useState(1);
    const [userTotalPages, setUserTotalPages] = useState(1);
    const [selectedModel, setSelectedModel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [hasPaid, setHasPaid] = useState(false); // Nouvel état pour suivre le statut de paiement
    const [email, setEmail] = useState(''); // Nouvel état pour stocker l'email de l'utilisateur
    const [isPublic, setIsPublic] = useState(false); // Nouvel état pour isPublic
    const [isPro, setIsPro] = useState(false); // Nouvel état pour vérifier si l'utilisateur a un abonnement Pro
    const [trainingFileCreatorModels, setTrainingFileCreatorModels] = useState([]);

    const url = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    const fetchTrainingFileCreatorModels = useCallback(async () => {
        try {
            const response = await fetch(`${url}/api/fine-tuning/training-file-creator-models`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setTrainingFileCreatorModels(data.models);
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage('Error fetching training file creator models');
        }
    }, [url]);

    const fetchUserInfo = useCallback(async () => {
        try {
            const response = await fetch(`${url}/api/user-info`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setEmail(data.email);

                // Fetch payment status only after email is set
                const paymentStatusResponse = await fetch(`${url}/api/check-payment-status?email=${data.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                    },
                    credentials: 'include',
                });
                const paymentStatusData = await paymentStatusResponse.json();
                if (paymentStatusResponse.ok) {
                    setHasPaid(paymentStatusData.hasPaid);
                    setIsPro(paymentStatusData.price_id === 'price_1QBHWGAeHcU4N7RPkto3e1Ia' || paymentStatusData.price_id === 'price_1QBHWGAeHcU4N7RPYNfLDAnu');
                } else {
                    setMessage(paymentStatusData.error);
                }
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage('Error fetching user info');
        }
    }, [url]);

    const fetchPublicModels = useCallback(async () => {
        try {
            const response = await fetch(`${url}/api/fine-tuning/public-models?page=${page}&limit=10`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setPublicModels(data.models);
                setTotalPages(data.totalPages);
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage('Error fetching public models');
        }
    }, [url, page]);

    const fetchUserModels = useCallback(async () => {
        try {
            const response = await fetch(`${url}/api/fine-tuning/user-models?page=${userPage}&limit=20`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setUserModels(data.models);
                setUserTotalPages(data.totalPages);
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage('Error fetching user models');
        }
    }, [url, userPage]);

    const refreshModels = () => {
        fetchPublicModels();
        fetchUserModels();
    };

    useEffect(() => {
        fetchUserInfo();
        fetchPublicModels();
        fetchUserModels();
        fetchTrainingFileCreatorModels();
    }, [fetchUserInfo, fetchPublicModels, fetchUserModels, fetchTrainingFileCreatorModels]);

    const handleModelSelect = async (model) => {
        if (!hasPaid && activeTab === 'public') {
            return;
        }
        setSelectedModel(model);

        try {
            const response = await fetch(`${url}/api/fine-tuning/jobs/${model.id}/status`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setIsPublic(data.is_public); // Set the initial state of isPublic
            } else {
                setMessage(data.error || 'Unknown error occurred');
            }
        } catch (error) {
            setMessage('Error fetching model status');
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleUserNextPage = () => {
        if (userPage < userTotalPages) {
            setUserPage(userPage + 1);
        }
    };

    const handleUserPrevPage = () => {
        if (userPage > 1) {
            setUserPage(userPage - 1);
        }
    };

    const cancelJob = async () => {
        if (!window.confirm('Are you sure you want to delete this model?')) {
            return;
        }

        try {
            const jobId = selectedModel.id;
            const response = await fetch(`${url}/api/fine-tuning/jobs/cancel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
                body: JSON.stringify({ job_id: jobId }),
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setSuccessMessage('Job cancelled and deleted successfully');
                setMessage('');
                // Remove the cancelled model from the state
                setUserModels(userModels.filter(model => model.id !== jobId));
            } else {
                setMessage(`Error cancelling job: ${data.error || 'Unknown error'}`);
            }
        } catch (error) {
            setMessage(`Error cancelling job: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const compareJobIds = async (jobId) => {
        try {
            const response = await fetch(`${url}/api/fine-tuning/jobs/${jobId}/status`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                return data.job_id === jobId;
            } else {
                setMessage(`Error fetching job status: ${data.error || 'Unknown error'}`);
                return false;
            }
        } catch (error) {
            setMessage(`Error fetching job status: ${error.message}`);
            return false;
        }
    };
    
    const copyModelName = async () => {
        try {
            const jobId = selectedModel.id;
            const isJobIdMatched = await compareJobIds(jobId);
            if (isJobIdMatched) {
                const response = await fetch(`${url}/api/fine-tuning/copy_model_name`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                    },
                    body: JSON.stringify({ job_id: jobId }),
                    credentials: 'include',
                });
                const data = await response.json();
                if (response.ok) {
                    navigator.clipboard.writeText(data.model_name);
                    setMessage(data.message);
                } else {
                    setMessage(data.error || 'Unknown error occurred');
                }
            } else {
                setMessage(`Job ID does not match: jobId=${jobId}`);
            }
        } catch (error) {
            setMessage(`Error copying Model Name: ${error.message}`);
        }
    };

    const togglePublicStatus = async () => {
        try {
            const jobId = selectedModel.id;
            const newIsPublic = !isPublic;
            const response = await fetch(`${url}/api/fine-tuning/jobs/update-public-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
                },
                body: JSON.stringify({ job_id: jobId, is_public: newIsPublic }),
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setIsPublic(newIsPublic);
                setMessage(data.message);
            } else {
                setMessage(data.error || 'Unknown error occurred');
            }
        } catch (error) {
            setMessage('Error updating public status');
        }
    };

    return (
        <div className="model-selection-popup">
            <div className="popup-content">
            <div className="tabs">
                {/* <button className={activeTab === 'jsonl-creator' ? 'active' : ''} onClick={() => setActiveTab('jsonl-creator')}>JSONL Creator Model</button> */}
                {/* <button className={activeTab === 'training-file-creator' ? 'active' : ''} onClick={() => setActiveTab('training-file-creator')}>Training File Creator</button> */}
                <button className={activeTab === 'fineurai-models' ? 'active' : ''} onClick={() => setActiveTab('fineurai-models')}>Fineur AI Models</button>
                <button className={activeTab === 'public' ? 'active' : ''} onClick={() => setActiveTab('public')}>Public Models</button>
                <button className={activeTab === 'user' ? 'active' : ''} onClick={() => setActiveTab('user')}>My Models</button>
            </div>
            <div className="model-grid">
                {/* {activeTab === 'jsonl-creator' && selectedModel && selectedModel.id === 'ftjob-iVid1GhvxEeSLvDce6dY41Y5' && (
                    <div className="model-details-popup">
                        <h2>{selectedModel.name}</h2>
                        <p><strong>Description:</strong> {selectedModel.description ? selectedModel.description.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        )) : 'No description available'}</p>
                    </div>
                )} */}
                {activeTab === 'public' && publicModels.map(model => (
                    <div key={model.id} className={`model-card ${!hasPaid ? 'disabled' : ''}`} onClick={() => handleModelSelect(model)}>
                        <img src={model.image || img1} alt={model.name} />
                        <p className={model.name.length > 20 ? 'long-text' : ''}>{model.name}</p>
                    </div>
                ))}
                {activeTab === 'fineurai-models' && trainingFileCreatorModels.map(model => (
                    <div key={model.id} className="model-card" onClick={() => handleModelSelect(model)}>
                        <img src={model.image || img1} alt={model.name} />
                        <p className={model.name.length > 20 ? 'long-text' : ''}>{model.name}</p>
                    </div>
                ))}
                {activeTab === 'user' && userModels.map(model => (
                    <div key={model.id} className="model-card" onClick={() => handleModelSelect(model)}>
                        <img src={model.image || img1} alt={model.name} />
                        <p className={model.name.length > 20 ? 'long-text' : ''}>{model.name}</p>
                    </div>
                ))}
            </div>
                {message && <p className="error-message">{message}</p>}
                {activeTab === 'public' && (
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
                        <span>Page {page} of {totalPages}</span>
                        <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
                    </div>
                )}
                {activeTab === 'user' && (
                    <div className="pagination">
                        <button onClick={handleUserPrevPage} disabled={userPage === 1}>Previous</button>
                        <span>Page {userPage} of {userTotalPages}</span>
                        <button onClick={handleUserNextPage} disabled={userPage === userTotalPages}>Next</button>
                    </div>
                )}
                <button className="refresh-button" onClick={refreshModels}>Refresh Models</button>
            </div>
            {selectedModel && (
                <div className="model-details-popup">
                    <h2>{selectedModel.name}</h2>
                    <p><strong>Hyperparameters:</strong> {JSON.stringify(selectedModel.hyperparameters)}</p>
                    <p><strong>Description:</strong> {selectedModel.description ? selectedModel.description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )) : 'No description available'}</p>
                    <button className="finetuning-button-blue" onClick={copyModelName}>Copy Model Name</button>
                    {isPro && (
                                <label>
                                    <input type="checkbox" checked={isPublic} onChange={togglePublicStatus} />
                                    Public
                                </label>
                            )}
                    <br />
                    {activeTab === 'user' && (
                        <>
                            <button className="finetuning-button-grey" onClick={cancelJob}>Delete Model</button>

                        </>
                    )}
                    {loading && <div className="loading-spinner"></div>}
                    {successMessage && <p className="success-message">{successMessage}</p>}
                </div>
            )}
        </div>
    );
}

export default ModelSelectionPopup;