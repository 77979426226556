import React, { useState, useEffect } from 'react';
import './NotificationPopup.css';

const NotificationPopup = () => {
  const [messages, setMessages] = useState(['Get a promo code at https://www.linkedin.com/company/fineurai']); // Message par défaut
  const url = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(url + `/api/fine-tuning/notifications`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
          },
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.messages) {
          setMessages(prevMessages => [...prevMessages, ...data.messages]);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
    const interval = setInterval(fetchNotifications, 10000);

    return () => clearInterval(interval);
  }, [url]);

  const handleClose = () => {
    setMessages([]);
  };

  return (
    <div className="notification-popup">
      <button className="close-button-notif" onClick={handleClose}>X</button>
      {messages.length > 0 && (
        <div className="notification-message">
          {messages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;